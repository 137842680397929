<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="6">
            <b-card>
                <b-form @submit.prevent="create">
                  <b-row>
                    <!-- name -->
                    <b-col cols="12">
                       <b-form-group
                        :label="$t('Name')"
                         label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Name')"
                          v-model="form.name"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- SEO Name -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Name (SEO)')"
                        label-for="v-seo-name"
                      >
                        <b-form-input
                          id="v-seo-name"
                          :placeholder="$t('Name (SEO)')"
                          v-model="category.seo_name"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- name_en -->
                    <!--<b-col cols="12">
                      <b-form-group
                        :label="$t('Name')"
                        label-for="v-name_en"
                      >
                        <b-form-input
                          id="v-name_en"
                          :placeholder="$t('Name en')"
                          v-model="form.name_en"
                        />
                      </b-form-group>
                    </b-col>-->
                    <!-- super_category_id -->
                    <!-- sort -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Sort')"
                        label-for="v-sort"
                      >
                        <b-form-input
                          id="v-sort"
                          type="tel"
                          placeholder="1"
                          v-model="form.sort"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Super Category')"
                        label-for="v-super_category_id"
                      >
                        <v-select
                          v-model="form.super_category_id"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="categories"
                        />
                        <b-form-text>{{$t('Select Super Category if this Category is Sub Category')}}</b-form-text>
                      </b-form-group>
                    </b-col>
                    <!-- Is Active -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Is Active ?')"
                        label-for="v-is-active"
                      >
                        <b-form-checkbox
                          id="v-is-active"
                          :checked="form.is_active == 1 ? true : false"
                          class="custom-control-primary"
                          @change="changeActive"
                          name="is_active"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <!-- Is Featured -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Is Featured ?')"
                        label-for="v-is-featured"
                      >
                        <b-form-checkbox
                          id="v-is-featured"
                          :checked="form.is_featured == 1 ? true : false"
                          class="custom-control-primary"
                          @change="changeFeatured"
                          name="is_featured"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
  },
  name: 'CategoriesCreate',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      form:{
        name: '',
        //name_en: '',
        is_active: 0,
        is_featured: 0,
        super_category_id: ''
      },
      snowOption: {},
      category: {},
      categories: []
    }
  },
  mounted() {
      this.getCategories();
  },
  methods: {
      getCategories(){
        useJwt.get('/categories/get-all')
        .then((response) => {
          this.categories = response.data.data.data
        })
        .catch(response => {
          // console.log(response);
        });
      },
    create() {
      this.isLoadingSave = true;
      console.log(this.form)
      useJwt.post('/categories/store',this.form)
      .then((response) => {
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message.name)
      });
    },
    changeFeatured(){
        if(this.form.is_featured==0) {
            this.form.is_featured=1
        } else {
            this.form.is_featured=0
        }
    },
    changeActive(){
        if(this.form.is_active==0) {
            this.form.is_active=1
        } else {
            this.form.is_active=0
        }
    }
  }
}
</script>
<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
